import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {concatMap, EMPTY, filter, of, Subscription} from "rxjs";
import {CommonModule} from "@angular/common";

// Сервисы
import {AppService} from "src/app/shared/services/app.service";
import {FormService} from "src/app/shared/services/form.service";
import {SettingsService} from "src/app/shared/services/settings.service";

import {CacheService} from "src/app/shared/services/cache.service";
import {NavigationService} from "src/app/shared/services/navigation.service";
import {AlfaIdService} from "landings/osago-ab/app/alfa-id/alfa-id.service";
import {AccordionService} from "src/app/shared/services/accordion.service";
import {YandexMetrikaService} from "src/app/shared/services/yandex-metrika.service";

// Компоненты
import {AdvantagesComponent} from "src/app/shared/components/advantages/advantages.component";
import {FaqComponent} from "src/app/shared/components/faq/faq.component";
import {AlfaIdComponent} from "landings/osago-ab/app/alfa-id/alfa-id.component";
import {
  ProlongationAlfaidComponent
} from "landings/osago-ab/app/alfa-id/prolongation-alfaid/prolongation-alfaid.component";
import { CompanySwiperAbComponent } from 'landings/osago-ab/app/components/company-swiper-ab/company-swiper-ab.component';
import {FooterComponent} from "landings/osago-ab/app/components/footer/footer.component";

// Интерфейсы
import {ROUTES} from "src/app/shared/enums/routes.enum";
import {ApiResponse} from "src/app/shared/interface/api-response";
import {IUserAlfaId} from "src/app/shared/interface/user-alfa-id";
import {YandexMetrikaGoalsEnum} from "src/app/shared/enums/yandex-metrika-goals.enum";
import {getTelegramWebApp} from "src/app/shared/functions/getTelegramWebApp";

@Component({
  selector: 'app-root',
  templateUrl: '../../osago-ab/app/app.component.html',
  standalone: true,
  imports: [
    CommonModule,
    CompanySwiperAbComponent,
    RouterOutlet,
    AdvantagesComponent,
    FaqComponent,
    FooterComponent,
    RouterLink,
    AlfaIdComponent
  ],
  styleUrls: ['../../osago-ab/app/app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private appService = inject(AppService);
  private cacheService = inject(CacheService);
  private router = inject(Router);
  private titleService = inject(Title);
  public formService = inject(FormService);
  private readonly navigationService = inject(NavigationService);
  private readonly route = inject(ActivatedRoute);
  private readonly alfaIdService = inject(AlfaIdService);
  private readonly accordionService = inject(AccordionService);
  private readonly ym = inject(YandexMetrikaService);
  private readonly settingsService = inject(SettingsService);

  protected readonly getTelegramWebApp = getTelegramWebApp;

  // Заголовок сайта
  private title = 'ОСАГО ОНЛАЙН';
  private faviconHref = 'src/assets/landingFiles/favicon.ico';
  private favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  // Подписка
  private subscription = new Subscription();

  // Индикатор загрузки
  public isLoading!: boolean;

  // Если главная страница
  public isMainPage = false;
  // Ошибка AlfaID
  public alfaIdError = false;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.replaceUrlForProlongationAlfaIDRoute();
    this.addProlongationAlfaIDRoute();

    this.initApp();

    this.subscription.add(
      this.route.queryParams
        .subscribe((params) => {
          for (const objectKey in params) {
            if (objectKey.toLowerCase() === 'prolongation') {
              if (params[objectKey] === 'true') {
                this.router.navigate(['prolongation-alfaid'], {
                  queryParams: {...params, Prolongation: false}
                });
              }
            } else if (objectKey.toLowerCase() === 'code') {
              if (params[objectKey] === 'true') {
              }
            }
          }
        })
    );

    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.isMainPage = this.router.url?.split('?')[0] === '/';
      })
    );

    this.titleService.setTitle(this.title);
    if (this.favIcon != null) {
      this.favIcon.href = this.faviconHref;
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Инициализация необъходимых данных перед стартом
  private initApp(): void {
    this.isLoading = true;
    this.alfaIdError = false;
    this.appService.isShowBtnAlfaId = true;

    const urlParams = this.appService.getUrlParams();

    this.subscription.add(
      this.appService.loadApp()
        .pipe(
          concatMap(() => {
            const isRouteProlongationAlfaId = this.router.url.split('/').pop()?.includes('prolongation-alfaid');
            const isRouteSuccessPage = this.router.url.split('/').pop()?.includes('success');

            if (urlParams.code && !isRouteProlongationAlfaId && !isRouteSuccessPage) {
              return this.alfaIdService.getAlfaUseFromCode(false);
            } else {
              return of(null);
            }
          }),
          concatMap((alfaIdData: ApiResponse<IUserAlfaId>) => {
            const isRouteProlongationAlfaId = this.router.url.split('/').pop()?.includes('prolongation-alfaid');
            const isRouteSuccessPage = this.router.url.split('/').pop()?.includes('success');

            if (alfaIdData === null) {
              return of(null);
            }

            if (!alfaIdData?.result || alfaIdData?.value?.error !== null) {
              this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.AlfaIdError);
              this.alfaIdError = true;
              return of(null);
            }

            if (alfaIdData && alfaIdData?.result && urlParams?.code && alfaIdData && !isRouteProlongationAlfaId && !isRouteSuccessPage) {
              return this.cacheService.getOsagoFromLocalStorage(urlParams, alfaIdData.value).pipe(
                concatMap(() => this.appService.getCarModels()),
              );
            } else {
              return of(null);
            }
          }),
        )
        .subscribe( {
          next: (res) => {
            const isRouteProlongationAlfaId = this.router.url.split('/').pop()?.includes('prolongation-alfaid');
            const isRouteSuccessPage = this.router.url.split('/').pop()?.includes('success');
            if (urlParams !== null
              && ((urlParams.applicationId
                && urlParams.src
                && urlParams.src !== 'Osago2Clicks'
                && urlParams.src !== 'DataCheckingBeforePaymentSms'
                && urlParams.offerId !== '0') || urlParams.alfaOfferId)) {
              setTimeout(() => {
                this.isLoading = false;
                this.navigationService.navigate(ROUTES.Offers);
              }, 1000);
            } else if (res && urlParams !== null && urlParams.code && !isRouteProlongationAlfaId && !isRouteSuccessPage) {
              setTimeout(() => {
                this.isLoading = false;
                this.appService.isShowBtnAlfaId = false;
                this.navigationService.navigate(ROUTES.Form);
                setTimeout(() => {
                  this.accordionService.accordionGroups.get(1)!.isOpen = true;
                }, 100);
              }, 100);

            } else if (urlParams !== null && urlParams.src === 'Osago2Clicks' && !isRouteProlongationAlfaId && !isRouteSuccessPage) {
              setTimeout(() => {
                this.isLoading = false;
                this.appService.isShowBtnAlfaId = false;
                this.formService.drivers.controls.forEach((driver, index) => {
                  // TODO тут нужно убрать валидацию в контроле "Дата выдачи паспорта"
                });
                this.navigationService.navigate(ROUTES.Form);
                setTimeout(() => {
                  const lastAccordionItem = this.accordionService.accordionGroups.length - 1;
                  this.accordionService.accordionGroups.get(lastAccordionItem)!.isOpen = true;
                }, 100);
              }, 100);

            } else if (this.alfaIdError && !isRouteSuccessPage) {
              this.isLoading = false;
              this.navigationService.navigate(ROUTES.MainPage);
              this.alfaIdError = false;
            } else {
              this.isLoading = false;
            }
          },
          error: (e) => {
            this.isLoading = false;
          }
        })
    );
  }


  // Добавляем страницу пролонгации alfaID
  public addProlongationAlfaIDRoute() {
    const newRoute = {
      path: 'prolongation-alfaid',
      component: ProlongationAlfaidComponent
    };

    this.router.config.push(newRoute);
  }

  private replaceUrlForProlongationAlfaIDRoute() {
    const currentUrl = window.location.href;

    // Ищем первый ?code и меняем его на &code
    const modifiedUrl = currentUrl.replace('?code', '&code');

    // Если ? не является первым символом (то есть если есть другие параметры перед ?code)
    if (currentUrl.includes('?code') && !currentUrl.includes('?') || currentUrl.indexOf('?code') !== currentUrl.indexOf('?')) {
      window.history.replaceState(null, '', modifiedUrl);
    }
  }
}
